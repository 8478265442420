import {NgxLoggerLevel} from 'ngx-logger';
import {LogLevel} from '@azure/msal-browser';

export const environment = {
  appVersion: require('../../package.json').version + '-ci',
  production: true,
  environment: 'nightly',
  loggingLevel: NgxLoggerLevel.WARN,
  msalLoggingLevel: LogLevel.Warning,
  authority: 'https://login.microsoftonline.com/common',
  azureClientId: '2dafdbbc-2ec9-4ab1-9d0b-d50f0ecd16b9',
  protectedResourceMap: [
    ['https://graph.microsoft.com/v1.0', ['User.Read']],
    ['/api/v1/Environment', null], // unprotected route have null scope
    ['/api/*', ['api://2dafdbbc-2ec9-4ab1-9d0b-d50f0ecd16b9/Api.Read']],
    // ... other scopes
  ],
  apiBaseUrl: window.location.origin,
  appInsights: {
    instrumentationKey: '7716c6cd-9095-4344-9923-444032a1f0de',
  },
};
